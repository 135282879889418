import React from 'react';
// import GenericSection from '../components/sections/AltaPotentia/GenericSection';
import FeaturesTilesAutobody from '../components/sections/AltaAuto/FeaturesTilesAuto';
import FeaturesSplitAutobody from '../components/sections/AltaAuto/FeaturesSplitAuto';
import TestimonialAutobody from '../components/sections/AltaAuto/TestimonialAuto';
import Cta from '../components/sections/Cta';
import HeroAuto from "../components/sections/AltaAuto/HeroAuto";


class Autobody extends React.Component {
    render() {

        return (
            <React.Fragment>
                <HeroAuto className="illustration-section-01" />
                <FeaturesTilesAutobody/>
                <FeaturesSplitAutobody/>
                <TestimonialAutobody/>
                <Cta topDivider split />
            </React.Fragment>
        );
    }
}

export default Autobody;