import React from 'react';
import GenericSection from '../components/sections/AltaMedia/GenericSection';
import FeaturesTiles from '../components/sections/AltaMedia/FeaturesTiles';
import FeaturesSplit from '../components/sections/AltaMedia/FeaturesSplit';
import Hero from "../components/sections/AltaMedia/Hero";
// import Testimonial from "../components/sections/AltaMedia/Testimonial";
import CtaContactMedia from '../components/sections/CtaContactMedia';
import Price from '../components/sections/AltaMedia/PricingSocial';

class Media extends React.Component {
    render() {


        return (
            <React.Fragment>
                <Hero/>
                <GenericSection>
                    <div className="hero-content">
                        <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
                            Alta <span className="text-color-primary">Media</span>
                        </h1>
                    </div>
                </GenericSection>

                <FeaturesTiles/>
                <FeaturesSplit/>

                <h1> Pricing </h1>
                <Price/>

                {/*<Testimonial/>*/}
                <div>

                    <title>Pricing example · Bootstrap v5.0</title>

                    <link rel="canonical" href="https://getbootstrap.com/docs/5.0/examples/pricing/"/>
                    <link href="../assets/dist/css/bootstrap.min.css" rel="stylesheet"></link>
                    <link href="../components/sections/AltaMedia/Price.css" rel="stylesheet"></link>
                    {/*<header class="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom shadow-sm">*/}
                    {/*    <p class="h5 my-0 me-md-auto fw-normal">Company name</p>*/}
                    {/*    <nav class="my-2 my-md-0 me-md-3">*/}
                    {/*        <a class="p-2 text-dark" href="#">Features</a>*/}
                    {/*        <a class="p-2 text-dark" href="#">Enterprise</a>*/}
                    {/*        <a class="p-2 text-dark" href="#">Support</a>*/}
                    {/*        <a class="p-2 text-dark" href="#">Pricing</a>*/}
                    {/*    </nav>*/}
                    {/*    <a class="btn btn-outline-primary" href="#">Sign up</a>*/}
                    {/*</header>*/}

                    {/*<main class="container">*/}
                    {/*    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">*/}
                    {/*        <h1 class="display-4">Pricing</h1>*/}
                    {/*        <p class="lead">Quickly build an effective pricing table for your potential customers with*/}
                    {/*            this Bootstrap example. It’s built with default Bootstrap components and utilities with*/}
                    {/*            little customization.</p>*/}
                    {/*    </div>*/}
                    {/*    <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">*/}
                    {/*        <div class="col">*/}
                    {/*            <div class="card mb-4 shadow-sm">*/}
                    {/*                <div class="card-header">*/}
                    {/*                    <h4 class="my-0 fw-normal">Free</h4>*/}
                    {/*                </div>*/}
                    {/*                <div class="card-body">*/}
                    {/*                    <h1 class="card-title pricing-card-title">$0 <small class="text-muted">/*/}
                    {/*                        mo</small></h1>*/}
                    {/*                    <ul class="list-unstyled mt-3 mb-4">*/}
                    {/*                        <li>10 users included</li>*/}
                    {/*                        <li>2 GB of storage</li>*/}
                    {/*                        <li>Email support</li>*/}
                    {/*                        <li>Help center access</li>*/}
                    {/*                    </ul>*/}
                    {/*                    <button type="button" class="w-100 btn btn-lg btn-outline-primary">Sign up for*/}
                    {/*                        free*/}
                    {/*                    </button>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div class="col">*/}
                    {/*            <div class="card mb-4 shadow-sm">*/}
                    {/*                <div class="card-header">*/}
                    {/*                    <h4 class="my-0 fw-normal">Pro</h4>*/}
                    {/*                </div>*/}
                    {/*                <div class="card-body">*/}
                    {/*                    <h1 class="card-title pricing-card-title">$15 <small class="text-muted">/*/}
                    {/*                        mo</small></h1>*/}
                    {/*                    <ul class="list-unstyled mt-3 mb-4">*/}
                    {/*                        <li>20 users included</li>*/}
                    {/*                        <li>10 GB of storage</li>*/}
                    {/*                        <li>Priority email support</li>*/}
                    {/*                        <li>Help center access</li>*/}
                    {/*                    </ul>*/}
                    {/*                    <button type="button" class="w-100 btn btn-lg btn-primary">Get started</button>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div class="col">*/}
                    {/*            <div class="card mb-4 shadow-sm">*/}
                    {/*                <div class="card-header">*/}
                    {/*                    <h4 class="my-0 fw-normal">Enterprise</h4>*/}
                    {/*                </div>*/}
                    {/*                <div class="card-body">*/}
                    {/*                    <h1 class="card-title pricing-card-title">$29 <small class="text-muted">/*/}
                    {/*                        mo</small></h1>*/}
                    {/*                    <ul class="list-unstyled mt-3 mb-4">*/}
                    {/*                        <li>30 users included</li>*/}
                    {/*                        <li>15 GB of storage</li>*/}
                    {/*                        <li>Phone and email support</li>*/}
                    {/*                        <li>Help center access</li>*/}
                    {/*                    </ul>*/}
                    {/*                    <button type="button" class="w-100 btn btn-lg btn-primary">Contact us</button>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    /!*<footer class="pt-4 my-md-5 pt-md-5 border-top">*!/*/}
                    {/*    /!*    <div class="row">*!/*/}
                    {/*    /!*        <div class="col-12 col-md">*!/*/}
                    {/*    /!*            <img class="mb-2" src="../assets/brand/bootstrap-logo.svg" alt="" width="24"*!/*/}
                    {/*    /!*                 height="19"/>*!/*/}
                    {/*    /!*            <small class="d-block mb-3 text-muted">&copy; 2017-2020</small>*!/*/}
                    {/*    /!*        </div>*!/*/}
                    {/*    /!*        <div class="col-6 col-md">*!/*/}
                    {/*    /!*            <h5>Features</h5>*!/*/}
                    {/*    /!*            <ul class="list-unstyled text-small">*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Cool stuff</a></li>*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Random feature</a></li>*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Team feature</a></li>*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Stuff for developers</a></li>*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Another one</a></li>*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Last time</a></li>*!/*/}
                    {/*    /!*            </ul>*!/*/}
                    {/*    /!*        </div>*!/*/}
                    {/*    /!*        <div class="col-6 col-md">*!/*/}
                    {/*    /!*            <h5>Resources</h5>*!/*/}
                    {/*    /!*            <ul class="list-unstyled text-small">*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Resource</a></li>*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Resource name</a></li>*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Another resource</a></li>*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Final resource</a></li>*!/*/}
                    {/*    /!*            </ul>*!/*/}
                    {/*    /!*        </div>*!/*/}
                    {/*    /!*        <div class="col-6 col-md">*!/*/}
                    {/*    /!*            <h5>About</h5>*!/*/}
                    {/*    /!*            <ul class="list-unstyled text-small">*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Team</a></li>*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Locations</a></li>*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Privacy</a></li>*!/*/}
                    {/*    /!*                <li><a class="link-secondary" href="#">Terms</a></li>*!/*/}
                    {/*    /!*            </ul>*!/*/}
                    {/*    /!*        </div>*!/*/}
                    {/*    /!*    </div>*!/*/}
                    {/*    /!*</footer>*!/*/}
                    {/*</main>*/}
                </div>
                <CtaContactMedia topDivider split/>
            </React.Fragment>
        );
    }
}
export default Media;