import React from 'react'
//import ReactDOM from 'react-dom'
//import JobApplication from 'react-job-app-form'



const CareerForm = () => {

//     const handleFormSubmit (formData) => {
//     console.log(formData) // hash of form input values
// }
// ReactDOM.render(<JobApplication onSubmit={handleFormSubmit} />, document.querySelector('body'))


    return (
        <section>
            <div>
            </div>
        </section>
    );
}




export default CareerForm;